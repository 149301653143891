export const menuData = [
  {
    title: "Privacy Policy",
    icon: "/images/icons/login.svg",
    link: "/privacy",
    showInHeader: false, // Show in header
    showInFooter: true, // Show in footer
  },
  {
    title: "Contact",
    icon: "/images/icons/login.svg",
    link: "mailto:info@gotradie.com.au",
    showInHeader: false, // Show in header
    showInFooter: true, // Show in footer
  },
  { 
    title: "How it works", 
    icon: "/images/icons/careers.svg",
    link: "/Features",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: false,
  },
  { 
    title: "Who's it for", 
    icon: "/images/icons/careers.svg",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: false,
  },
  { 
    title: "Why change", 
    icon: "/images/icons/careers.svg",
    link: "/Why",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: false,
  },
  { 
    title: "Pricing", 
    icon: "/images/icons/careers.svg",
    link: "/pricing",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: false,
  },
  { 
    title: "Careers", 
    icon: "/images/icons/careers.svg",
    link: "/careers",
    showInHeader: false, // Show in header
    showInFooter: true, // Show in footer
  },
  {
    title: "Log in",
    icon: "/images/icons/login.svg",
    link: "https://app.gotradie.com.au/login",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: true,
  },
  {
    title: "Create account",
    icon: "/images/icons/signup.svg",
    link: "https://app.gotradie.com.au/signup",
    showInHeader: true, // Show in header
    showInFooter: false, // Show in footer
    showBackground: true,

  },
]

export const tooltipData = [
  { title: "How it works", icon: "/images/icons/how.svg", link: "/Features", showBackground: true },
  {
    title: "Who's it for",
    icon: "/images/icons/arrow-down.svg",
    showBackground: true,
    subLinks: [
      { title: "Home builders", emoji: "🔨", link: "/Builders" },
      { title: "Landscapers", emoji: "🌳", link: "/Landscapers" },
      { title: "Electrician & solar", emoji: "⚡", link: "/Electricians" },
      { title: "Maintenance & repair", emoji: "🪠", link: "/Maintenance" },
      { title: "Gardeners", emoji: "🪴", link: "/Gardeners" },
      { title: "Construction", emoji: "🏗️", link: "/Construction" },
      { title: "HVAC", emoji: "☀️", link: "/HVAC" },
      { title: "Traffic control", emoji: "🚦", link: "/Traffic" },
      { title: "Civil works", emoji: "🚧", link: "/Civil" },
      { title: "Cleaning", emoji: "🧹", link: "/Cleaning" },
      { title: "Roofing", emoji: "🏠", link: "/Roofing" },
      { title: "Architect", emoji: "📐", link: "/Architects" },
      { title: "Commercial fit-out", emoji: "🏢", link: "/FitOut" },
      { title: "Carpenters", emoji: "🪚", link: "/Carpenters" },
      { title: "Steel fabrication", emoji: "🔩", link: "/SteelFabrication" },
      { title: "Project management", emoji: "📋", link: "/ProjectManagement" },
      { title: "Suppliers", emoji: "📦", link: "/Suppliers" },
    ],
  },
  { title: "Why change", icon: "/images/icons/info.svg", link: "/Why", showBackground: true},
  { title: "Pricing", icon: "/images/icons/Wallet.png", link: "/pricing", showBackground: true},
  { title: "Sign up", icon: "/images/icons/signup.svg", link: "https://app.gotradie.com.au/signup", showBackground: true },
  { title: "Log in", icon: "/images/icons/login.svg", link: "https://app.gotradie.com.au/login", showBackground: true },
  { title: "Careers", icon: "/images/icons/careers.svg", link: "/careers", showBackground: true },
]